import '../styles/index.scss';

import {tns} from 'tiny-slider/src/tiny-slider';
import AOS from 'aos';

import './base';
import './animations';
import './projects';

AOS.init();

const portfolio = tns({
  container: '#case-studies-slider',
  items: 1,
  slideBy: 'page',
  autoplay: true,
  autoplayHoverPause: true,
  gutter: 50,
  autoplayButtonOutput: false,
  autoHeight: true,
});

// const partners = tns({
//   container: '#partners-slider',
//   items: 5,
//   slideBy: 'page',
//   autoplay: true,
//   autoplayHoverPause: true,
//   gutter: 50,
//   autoplayButtonOutput: false,
// });