const caseStudiesSlider = document.querySelector('#case-studies-slider');
const allProjectsElements = caseStudiesSlider.querySelectorAll('.project__image-wrapper');
let scrollProjectReq = null;
let hovered = false;

function scrollProjects() {
  const activeSlide = caseStudiesSlider.querySelector('.tns-item.tns-slide-active');
  const projectsElements = activeSlide.querySelectorAll('.project__image-wrapper:not(.project__image-wrapper--without-scroll) .project__image-body .project__image-content');

  projectsElements.forEach((el) => {
    el.scrollTo(0, el.scrollTop + 1);
  });

  scrollProjectReq = window.requestAnimationFrame(() => scrollProjects());
}

if (caseStudiesSlider) {
  caseStudiesSlider.addEventListener('mouseenter', () => {
    if (!hovered) {
      scrollProjects();
    }
  });

  caseStudiesSlider.addEventListener('mouseleave', () => {
    cancelAnimationFrame(scrollProjectReq);
  });

  allProjectsElements.forEach((el) => {
    el.addEventListener('mouseenter', () => {
      hovered = true;

      cancelAnimationFrame(scrollProjectReq);
    });

    el.addEventListener('mouseleave', () => {
      hovered = false;

      scrollProjects();
    });
  });
}

