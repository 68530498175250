const animatedElements = document.querySelectorAll('.animation');

function checkAndStartAnimations(elements) {
  const windowHeight = window.innerHeight;

  elements.forEach((el) => {
    const topOfWindow = window.scrollY;
    const thisPos = el.getBoundingClientRect().top + topOfWindow;

    if (topOfWindow + windowHeight - 100 > thisPos) {
      el.classList.add("animation--started");
    }
  });
}

window.addEventListener('scroll', () => {
  checkAndStartAnimations(animatedElements);
});

checkAndStartAnimations(animatedElements);
